import Footer from "@/components/Footer";
import Navbar from "@/components/Navbar";


const ProtectedLayout = ({ children }) => {


  return (
    <>
      {/* <!-- ===== Page Wrapper Start ===== --> */}
      <div className="flex w-full bg-gray-800  no-scrollbar">

        {/* <!-- ===== Content Area Start ===== --> */}
        <div className="relative flex flex-1 flex-col">
          {/* <!-- ===== Header Start ===== --> */}
          <Navbar />
          {/* <!-- ===== Header End ===== --> */}

          {/* <!-- ===== Main Content Start ===== --> */}
          <main className='w-screen pt-28 bg'>
            <div className="mx-auto w-full p-1  ">
              {children}
            </div>
          </main>
          {/* <!-- ===== Main Content End ===== --> */}
          <Footer />
        </div>
        {/* <!-- ===== Content Area End ===== --> */}
      </div>
      {/* <!-- ===== Page Wrapper End ===== --> */}
    </>
  )
};

export default ProtectedLayout;

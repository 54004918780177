import { setCategories, setLoading, setError } from "../reducers/CategoryReducers";
import { apiSlice } from "../services/api";

export const CategoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => ({
        url: "/categories",
        method: "GET",
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          dispatch(setLoading(true));
          const { data } = await queryFulfilled;
          dispatch(setCategories(data));
          dispatch(setLoading(false));
        } catch (error) {
          dispatch(setError(error));
          dispatch(setLoading(false));
        }
      },
    }),


    
    getCategories: builder.mutation({
      query: () => ({
        url: "/categories",
        method: "GET",

      })
    }),
    createCategory: builder.mutation({
      query: (formData) => ({
        url: '/category',
        method: 'POST',
        body: formData,
      }),
    }),
    getCategoryById: builder.query({
      query: (id) => ({
        url: `/category/${id}`,
        method: "GET",
      }),
    }),
    updateCategory: builder.mutation({
      query: ({ id, formData }) => ({
        url: `/category/${id}`,
        method: 'PUT',
        body: formData,
      }),
    }),
    deleteCategory: builder.mutation({
      query: (id) => ({
        url: `/category/${id}`,
        method: "DELETE",
      }),
    }),


  }),
});

export const {
  useCreateCategoryMutation,
  useGetCategoryByIdQuery,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useGetCategoriesMutation,
} = CategoryApiSlice;

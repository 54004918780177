import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';


// import required modules
import { Autoplay, Pagination } from 'swiper/modules';
import Image from 'next/image';
import Link from 'next/link';
import { mainCarousel } from '@/Data';


export default function MainCarousel() {
  const swiperRef = useRef(null);
  const [loading, setLoading] = useState(true);


  // Simulate data fetching delay for demonstration purposes
  useEffect(() => {
    if (mainCarousel.length > 0) {
      setLoading(false);
    }
  }, [mainCarousel]);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };


  // Function to go to the next slide
  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };


  return (
    <div className="relative h-40 md:h-96">
      <Swiper
        ref={swiperRef}
        pagination={true}
        modules={[Autoplay, Pagination]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        className="mySwiper h-full rounded-lg">
        {mainCarousel.map((item, index) => (
          <SwiperSlide key={index}>
            <Link href={`${item.link}`}>
              <Image
                className='w-full h-full  object-fill   bg-gray-800'
                src={item.image}
                width={1920}
                height={100}
                quality={75}
                priority
                alt={item.name}
              />
            </Link>
          </SwiperSlide>
        ))}

      </Swiper>

      {/* Custom Navigation Buttons */}
      {/* <div className={`absolute top-1/2 hidden transform -translate-y-1/2 md:flex justify-between w-full px-2 z-10 ${loading ? "hidden" : ""}`}>
          <button
           aria-label="previous"
            className="bg-blue-500/50 text-white rounded-full p-2 hover:bg-blue-600 transition duration-200"
            onClick={handlePrev} // Use the handler for previous slide
          >
           <svg className="w-5 h-5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 19-7-7 7-7"/>
</svg>

          </button>
          <button
           aria-label="next"
            className="bg-blue-500/50 text-white rounded-full p-2 hover:bg-blue-600 transition duration-200"
            onClick={handleNext}
          >
           <svg className="w-5 h-5 text-white font-bold" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m9 5 7 7-7 7"/>
</svg>

          </button>
        </div> */}
    </div>
  );
}

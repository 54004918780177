import React from 'react';
import ProductCard from '../Product/productcard';
import { useRouter } from 'next/navigation';

export default function HomeAppliances({ products }) {
    const route = useRouter();

    const handleProductClick = (slug) => {
        route.push(`/products/${slug}`);
    };

    //   if not filtred on backend use this
    const filteredProducts = products?.filter(product => product?.category?.name === 'Appliances')

    return (
        <div className="bg-gradient-to-r  from-gray-700 via-gray-400 to-gray-500">
            <div className='bg-red-100 justify-center flex items-center lg:p-2  rounded-t'>
                <h2 className='text-gray-500 font-semibold md:text-xl'>Top Deals Home Appliances</h2>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 p-1 lg:grid-cols-7  ">
                {filteredProducts?.slice(0 , 14).map(item => (
                    <ProductCard product={item} handleProductClick={handleProductClick} />

                ))}
            </div>
        </div>
    );
}

import { createSlice } from '@reduxjs/toolkit';

const topCorauselSlice = createSlice({
  name: 'topCorausel',
  initialState: {
    topCorausels: [],
    loading: false,
    error: null
  },
  reducers: {
    setTopCorausels: (state, action) => {
      state.topCorausels = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const { setTopCorausels, setLoading, setError } = topCorauselSlice.actions;
export default topCorauselSlice.reducer;
export const selectTopCorausels = (state) => state.topCorausels.topCorausels;


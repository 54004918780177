"use client"
import Link from 'next/link';
import React, { use, useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import ProductCard from '../Product/productcard';
import { useRouter } from 'next/navigation';


// Import required modules

const PromotionalSection = ({ title, product, seeAllLink, colour, txtColour, countdownDate }) => {
    const [timeLeft, setTimeLeft] = useState({});
    const route = useRouter();

    useEffect(() => {
        const calculateTimeLeft = () => {
            const difference = new Date(countdownDate) - new Date();
            if (difference > 0) {
                return {
                    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                    minutes: Math.floor((difference / 1000 / 60) % 60),
                    seconds: Math.floor((difference / 1000) % 60),
                };
            }
            return {};
        };

        const timer = setInterval(() => setTimeLeft(calculateTimeLeft()), 1000);
        return () => clearInterval(timer);
    }, [countdownDate]);

    const formatTimeLeft = ({ days, hours, minutes, seconds }) =>
        `${days ? days + 'd ' : ''}${hours}h ${minutes}m ${seconds}s`;
    const swiperRef = useRef(null);

// product click
  

    // Function to go to the previous slide
    const handlePrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    // Function to go to the next slide
    const handleNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };



    return (
        <div className=" bg-white rounded">
            {/* Top Section */}
            <div className={`${colour} text-${txtColour} flex py-1 justify-between h-auto rounded-t px-2 product-center`}>
                <h2 className={` text-xl font-bold`}>{title}</h2>
                {countdownDate &&
                    <p className=" text-white">

                        <span className="font-semibold">Ends in: </span>
                        {Object.keys(timeLeft).length ? formatTimeLeft(timeLeft) : 'Sale ended'}
                    </p>}

                <Link href="/catalog">See All</Link>
            </div>

            <div className="relative px-2 py-1 "> {/* Positioning context for absolute elements */}
                <Swiper
                    ref={swiperRef}
                    className="mySwiper h-full"
                    slidesPerView={2}
                    spaceBetween={2}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                        },
                        768: {
                            slidesPerView: 4,
                        },
                        1024: {
                            slidesPerView: 7,
                        },
                    }}
                >
                    {product.map((item) => (

                        <SwiperSlide>
                            <ProductCard  product={item} />
                        </SwiperSlide>

                    ))}

                    {/* Custom Navigation Buttons */}
                    <div className="absolute top-1/2 transform -translate-y-1/2 flex justify-between w-full px-2 z-10">
                        <button
                            aria-label="previous"
                            className="bg-blue-500/50 text-white rounded-full p-2 hover:bg-blue-600 transition duration-200"
                            onClick={handlePrev}
                        >
                            <svg className="w-5 h-5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 19-7-7 7-7" />
                            </svg>

                        </button>
                        <button
                            aria-label="next"
                            className="bg-blue-500/50 text-white rounded-full p-2 hover:bg-blue-600 transition duration-200"
                            onClick={handleNext}
                        >
                            <svg className="w-5 h-5 text-white font-bold" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m9 5 7 7-7 7" />
                            </svg>

                        </button>
                    </div>
                </Swiper>
            </div>
        </div>
    );
};

export default PromotionalSection;





'use client';
import React, { useEffect, useState } from 'react';
import PromotionalSection from '../PromotionalSection';
import HomeAppliances from '../PromotionalSection/HomeAppliances';
import { useSelector } from 'react-redux';
import { selectProducts } from '@/reducers/productReducers';

const HomePage = () => {
    const products = useSelector(selectProducts);
    const [loading, setLoading] = useState(true);

    // Simulate data fetching delay for demonstration purposes
    useEffect(() => {
        if (products.length > 0) {
            setLoading(false);
        }
    }, [products]);

    const sortedProducts = [...products]
        .sort((a, b) => b.discount - a.discount);
   
    const sortedSupermarket = products?.filter(product => product?.category?.name === 'Supermarket')
    const sortedProductsNew = [...products].sort((a, b) => b.id - a.id);


    return (
        <div className="flex flex-col gap-4">
            <PromotionalSection
                title="Black Friday Deals"
                product={sortedProducts}
                txtColour={"white"}
                colour="bg-orange-500"
                countdownDate="2024-11-30T23:59:59"
                seeAllLink="/top-deals"
            />
            <PromotionalSection
                title="Top Deals"
                product={products}
                txtColour={"white"}
                colour="bg-yellow-500"
                seeAllLink="/top-deals"

            />
            <HomeAppliances products={products} />
            <PromotionalSection
                title="Supermarket Deals"
                product={sortedSupermarket}
                txtColour={"white"}
                colour="bg-green-500"
                seeAllLink="/supermarket"
            />
            <PromotionalSection
                title="Exclusive Offers"
                product={sortedProductsNew} // Example: you could filter this further based on your criteria
                txtColour={"white"}
                colour="bg-purple-500"
                seeAllLink="/exclusive-offers"
            />
            {/* Add more sections as needed */}
        </div>
    );
};

export default HomePage;

import { apiSlice } from "../services/api";

export const mainCorauselApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMainCorausels: builder.mutation({
      query: () => ({
        url: "/main-carousels",
        method: "GET",
      }),
    }),
    createMainCorausel: builder.mutation({
      query: (formData) => ({
        url: '/main-carousel',
        method: 'POST',
        body: formData,
       
      }),
    }),
    getMainCorauselById: builder.query({
      query: (id) => ({
        url: `/main-carousel/${id}`,
        method: "GET",
      }),
    }),
    updateMainCorausel: builder.mutation({
      query: ({ id, formData }) => ({
        url: `/main-carousel/${id}`,
        method: 'PUT',
        body: formData,
      }),
    }),
    deleteMainCorausel: builder.mutation({
      query: (id) => ({
        url: `/main-carousel/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetMainCorauselsMutation,
  useCreateMainCorauselMutation,
  useGetMainCorauselByIdQuery,
  useUpdateMainCorauselMutation,
  useDeleteMainCorauselMutation,
} = mainCorauselApiSlice;

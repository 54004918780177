import { apiSlice } from "../services/api";

export const topCorauselApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTopCorausels: builder.mutation({
      query: () => ({
        url: "/top-carousels",
        method: "GET",
      }),
    }),
    createTopCorausel: builder.mutation({
      query: (formData) => ({
        url: '/topcorausel',
        method: 'POST',
        body: formData,
       
      }),
    }),
    getTopCorauselById: builder.query({
      query: (id) => ({
        url: `/topcorausel/${id}`,
        method: "GET",
      }),
    }),
    updateTopCorausel: builder.mutation({
      query: ({ id, formData }) => ({
        url: `/topcorausel/${id}`,
        method: 'PUT',
        body: formData,
      }),
    }),
    deleteTopCorausel: builder.mutation({
      query: (id) => ({
        url: `/topcorausel/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetTopCorauselsMutation,
  useCreateTopCorauselMutation,
  useGetTopCorauselsByIdQuery,
  useUpdateTopCorauselMutation,
  useDeleteTopCorauselMutation,
} = topCorauselApiSlice;

import { apiSlice } from "../services/api";

export const ProductApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createProduct: builder.mutation({
      query: ({ formData }) => ({
        url: "/product",
        method: "POST",
        body: formData
      }),
    }),
    getProducts: builder.mutation({
      query: () => ({
        url: "/products",
        method: "GET",
      }),
    }),
    getProduct: builder.query({
      query: ({ id }) => ({
        url: `/product/${id}`,
        method: "GET",
      }),
    }),
    // getProductSlug: builder.query({
    //   query: ({slug}) => ({
    //     console.log();

    //   url: `/product/slug/${slug}`,
    //   method: "GET",
    //   }),
    // }),
    getProductSlug: builder.mutation({
      query: (slug) => ({
        url: `/productslug?slug=${slug}`,
        method: "PUT",
        body: formData,
      }),
    }),
    updateProduct: builder.mutation({
      query: ({ id, formData }) => ({
        url: `/product/${id}`,
        method: "PUT",
        body: formData,
      }),
    }),
    updateProductStatus: builder.mutation({
      query: ({ id, formData }) => ({
        url: `/product/status/${id}`,
        method: "PUT",
        body: formData,
      }),
    }),
    deleteProduct: builder.mutation({
      query: ({ id }) => ({
        url: `/product/${id}`,
        method: "DELETE",
      }),
    }),

  }),
});

export const {
  useGetProductsMutation,
  useCreateProductMutation,
  useUpdateProductMutation,
  useUpdateProductStatusMutation,
  useGetProductQuery,
  // useGetProductSlugQuery,
  useGetProductSlugMutation,
  useDeleteProductMutation
} = ProductApiSlice;


"use client";
import MainCorausel from "@/components/LandingPage/MainCarousel";
import ProtectedLayout from "./protected-layout";
import TopRightCarousel from "@/components/LandingPage/TopRightCarousel";
import Sidebar from "@/components/LandingPage/SideBar";
import Recommend from "@/components/LandingPage/Recommend/Recemmend";
import HomePage from "@/components/HomePagePromotion";

import { useGetMainCorauselsMutation } from "@/actions/mainCorauselActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useCallback, useState } from "react";
import { setMainCorausels } from "@/reducers/mainCorauselReducers";
import { toast } from "react-toastify";
import { useGetTopCorauselsMutation } from "@/actions/topCorauselActions";
import {
  selectTopCorausels,
  setTopCorausels,
} from "@/reducers/topCorauselReducers";
import { useGetCategoriesMutation } from "@/actions/categoryAction";
import { setCategories, setLoading } from "@/reducers/CategoryReducers";
import {
  selectBottomCorausels,
  setBottomCorausels,
} from "@/reducers/bottomCorauselReducer";
import { useGetBottomCorauselsMutation } from "@/actions/bottomCorauselActions";
import Image from "next/image";
import { useGetProductsMutation } from "@/actions/productAction";
import { setProducts } from "@/reducers/productReducers";
import { bottomItems, topItems } from "@/Data";

const LandingPage = () => {
  // Fetch Categories
  const [getCategories] = useGetCategoriesMutation();
  const dispatch = useDispatch();
  const [getMainCorausels] = useGetMainCorauselsMutation();
  const [getTopCorausels] = useGetTopCorauselsMutation();
  const [getBottomCorausels] = useGetBottomCorauselsMutation();
  const [getProducts] = useGetProductsMutation();
  const topCorausels = useSelector(selectTopCorausels);
  const bottomCorausels = useSelector(selectBottomCorausels);
  const [loading, setLoading] = useState(true);

  const fecthData = useCallback(async () => {
    setLoading(true);
    try {
      const res = await getCategories().unwrap();
      if (!res.error) {
        dispatch(setCategories(res));
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  }, [getCategories]);

  useEffect(() => {
    fecthData();
  }, [fecthData]);

  useEffect(() => {
    async function fetchMainCorausels() {
      setLoading(true);
      try {
        const response = await getMainCorausels().unwrap();
        if (!response.error) {
          dispatch(setMainCorausels(response));
        }
      } catch (err) {
        toast.error(err);
      } finally {
        setLoading(false);
      }
    }

    async function fetchTopCorausels() {
      setLoading(true);
      try {
        const response = await getTopCorausels().unwrap();
        if (!response.error) {
          dispatch(setTopCorausels(response));
        }
      } catch (err) {
        toast.error(err);
      } finally {
        setLoading(false);
      }
    }

    async function fetchBottomCorausels() {
      setLoading(true);
      try {
        const response = await getBottomCorausels().unwrap();
        if (!response.error) {
          dispatch(setBottomCorausels(response));
        }
      } catch (err) {
        toast.error(err);
      } finally {
        setLoading(false);
      }
    }

// fetch products
    async function fetchProducts() {
      setLoading(true);
      try {
        const response = await getProducts().unwrap();
        if (!response.error) {
          dispatch(setProducts(response));
        }
      } catch (err) {
        toast.error(err);
      } finally {
        // setLoading(false);
      }
    }

    fetchMainCorausels();
    fetchTopCorausels();
    fetchBottomCorausels();
    fetchProducts();
  }, [dispatch, getMainCorausels, getTopCorausels, getBottomCorausels, getProducts]);

  // Simulate data fetching delay for demonstration purposes
  useEffect(() => {
    if (topCorausels.length > 0) {
      setLoading(false);
    }
    if (bottomCorausels.length > 0) {
      setLoading(false);
    }
  }, [topCorausels, bottomCorausels]);
  return (
    <ProtectedLayout>
      <main className="flex flex-col gap-4">
        <div className="w-full h-40 md:h-96 md:flex md:flex-row gap-2 ">
          <div className="lg:w-1/6 w-2/2 rounded-lg h-full hidden md:block">
            <Sidebar />
          </div>
          <div className="w-full h-40 md:h-96 flex-1  md:w-4/6 shadow-sm shadow-blue-200 rounded-lg">
          {loading ? (
                <div className="relative overflow-hidden rounded-lg h-full flex items-center justify-center shadow-sm shadow-blue-200 animate-pulse bg-gray-100 ">
                 <Image src="/assets/images/animate.gif" width={50} height={50} priority alt="fav"/>
                </div>
              ) : (
            <MainCorausel />
              )}
          </div>
          {/* write functions for this slides */}
          <div className="w-1/6 hidden md:hidden lg:flex md:flex-col gap-1 ">
            <div className="relative w-full h-1/2 overflow-hidden">
            {loading ? (
                <div className="relative overflow-hidden rounded-lg h-full flex items-center justify-center shadow-sm shadow-blue-200 animate-pulse bg-gray-100 ">
                 <Image src="/assets/images/animate.gif" width={50} height={50} priority alt="fav"/>
                </div>
              ) : (
              <div className="relative overflow-hidden rounded-lg h-full  shadow-sm shadow-blue-200">
                <TopRightCarousel
                  CorauselData={topItems}
                  loading={loading}
                  delay={3000}
                  direction="vertical"
                />
              </div>
                )}
            </div>
            {/* bottom */}

            <div className="relative w-full h-1/2 ">
              {loading ? (
                <div className="relative overflow-hidden rounded-lg h-full flex items-center justify-center shadow-sm shadow-blue-200 animate-pulse bg-gray-100 ">
                 <Image src="/assets/images/animate.gif" width={50} height={50} priority alt="fav"/>
                </div>
              ) : (
                <div className="relative overflow-hidden rounded-lg h-full shadow-sm shadow-blue-200 ">
                  <TopRightCarousel
                    CorauselData={bottomItems}
                    loading={loading}
                    delay={3000}
                    direction="horizontal"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {loading ? (
  <div className="grid grid-cols-3 md:grid-cols-8 gap-4">
    {Array.from({ length: 8 }).map((_, index) => (
      <div key={index} className="relative overflow-hidden rounded-lg h-32 flex items-center justify-center shadow-sm shadow-blue-200 animate-pulse bg-gray-100">
        <div className="animate-pulse flex flex-col justify-center items-center w-full mx-auto p-5">
          <Image src="/assets/images/animate.gif" width={50} height={50} priority alt="fav" />
        </div>
      </div>
    ))}
  </div>
) : (
  <div className="p-2 py-6 rounded-lg bg-gradient-to-br from-blue-100 via-slate-300 to-gray-200  md:px-3 items-center">
    <Recommend />
  </div>
)}

        <section>
          <HomePage />
        </section>
      </main>
    </ProtectedLayout>
  );
};

export default LandingPage;

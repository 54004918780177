import { apiSlice } from "../services/api";

export const bottomCorauselApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBottomCorausels: builder.mutation({
      query: () => ({
        url: "/bottom-carousels",
        method: "GET",
      }),
    }),
    createBottomCorausel: builder.mutation({
      query: (formData) => ({
        url: '/bottom-carousel',
        method: 'POST',
        body: formData,
       
      }),
    }),
    getBottomCorauselById: builder.query({
      query: (id) => ({
        url: `/bottom-carousel/${id}`,
        method: "GET",
      }),
    }),
    updateBottomCorausel: builder.mutation({
      query: ({ id, formData }) => ({
        url: `/bottom-carousel/${id}`,
        method: 'PUT',
        body: formData,
      }),
    }),
    deleteBottomCorausel: builder.mutation({
      query: (id) => ({
        url: `/bottom-carousel/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetBottomCorauselsMutation,
  useCreateBottomCorauselMutation,
  useGetBottomCorauselsByIdQuery,
  useUpdateBottomCorauselMutation,
  useDeleteBottomCorauselMutation,
} = bottomCorauselApiSlice;

import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

function ProductCard({ product, handleAddToWishlist }) {
  const currentPrice = product?.currentPrice.toLocaleString('en-KE', {
    minimumFractionDigits: 2,
  });
  const previousPrice = product?.previousPrice.toLocaleString('en-KE', {
    minimumFractionDigits: 2,
  });

  // Fallback image path
  const fallbackImage = "/assets/images/animate.gif";

  return (
    <div className="w-full h-56 sm:h-64 max-w-sm bg-white border border-gray-200 rounded-xl shadow duration-300 ease-in-out transform scale-95 hover:scale-100 hover:border-blue-800 relative">
      <Link
        className="h-full w-full"
        href={{
          pathname: '/products',
          query: { slug: product.slug },
        }}
      >

        {product?.discount != null && product.discount > 0 ? (
          <p className="absolute bg-yellow-400/60 text-black rounded-br-xl px-2 text-sm font-serif">
            -{product.discount}%
          </p>
        ) : (
          <p className="absolute bg-green-400/60 text-black rounded-br-xl px-2 text-sm font-serif">
            New
          </p>
        )}

        <svg
          onClick={() => handleAddToWishlist(2, product.id, 1)}
          className="absolute right-0 w-6 h-6 text-red-600 hover:fill-red-600"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12.01 6.001C6.5 1 1 8 5.782 13.001L12.011 20l6.23-7C23 8 17.5 1 12.01 6.002Z"
          />
        </svg>

        <Image
          className={`py-0 w-full h-[60%] ${!product?.image ? 'animate-pulse bg-slate-200' : ''} rounded-t-lg object-fill object-center`}
          src={product?.image || fallbackImage} // Use fallback image if no image is provided
          width={200}
          height={150}
          loading="lazy"
          alt={product?.name}
          onError={(e) => {
            e.target.src = fallbackImage; // Fallback to the animate.gif if error occurs
          }}
        />





        <div className="px-2 absolute bottom-1">
          <h2 className="text-md line-clamp-1 text-start tracking-tight text-black">
            {product.name}
          </h2>
          <div className="text-start w-full">
            <div className="flex flex-col ">
              <span className="text-md font-serif font-md text-gray-950 ">
                ksh: {currentPrice}
              </span>
              {product.previousPrice > 0 && (
                <span className="text-sm font-serif -mt-1 ms-2 text-gray-900 line-through">
                  ksh: {previousPrice}
                </span>
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default ProductCard;

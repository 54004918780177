import { createSlice } from '@reduxjs/toolkit';

const bottomCorauselSlice = createSlice({
  name: 'bottomCorausel',
  initialState: {
    bottomCorausels: [],
    loading: false,
    error: null
  },
  reducers: {
    setBottomCorausels: (state, action) => {
      state.bottomCorausels = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const { setBottomCorausels, setLoading, setError } = bottomCorauselSlice.actions;
export default bottomCorauselSlice.reducer;
export const selectBottomCorausels = (state) => state.bottomCorausels.bottomCorausels;


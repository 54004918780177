import { createSlice } from '@reduxjs/toolkit';

const mainCorauselSlice = createSlice({
  name: 'mainCorausel',
  initialState: {
    mainCorausels: [],
    loading: false,
    error: null
  },
  reducers: {
    setMainCorausels: (state, action) => {
      state.mainCorausels = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const { setMainCorausels, setLoading, setError } = mainCorauselSlice.actions;
export default mainCorauselSlice.reducer;
export const selectMainCorausels = (state) => state.mainCorausels.mainCorausels

import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css/autoplay';

// Import required modules
import { Autoplay } from 'swiper/modules';
import RecommendCard from './RecommendCard';
import { useGetRecommendedsMutation } from '@/actions/recommendedActions';
import { selectRecommendeds, setRecommendeds } from '@/reducers/recommendedReducers';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { bottomCarousel } from '@/Data';

export default function Recommend() {
  const swiperRef = useRef(null);
  const dispatch = useDispatch();
  const [getRecommendeds] = useGetRecommendedsMutation();
  const recommendeds = useSelector(selectRecommendeds);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchRecommendeds() {
      setLoading(true);
      try {
        dispatch(setRecommendeds(bottomCarousel));
      } catch (err) {
        toast.error(err);
      } finally {
        setLoading(false);
      }
    }

    fetchRecommendeds();
  }, [dispatch, getRecommendeds]);

  useEffect(() => {
    if (recommendeds.length > 0) {
      setLoading(false);
    }
  }, [recommendeds]);
  // Function to go to the previous slide
  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  // Function to go to the next slide
  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };


  return (
    <div className="relative  "> {/* Positioning context for absolute elements */}
      <Swiper
        ref={swiperRef}
        modules={[Autoplay]} // Only include Autoplay
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        className="mySwiper mx-auto "
        slidesPerView={4}
        spaceBetween={0}
        breakpoints={{
          640: {
            slidesPerView: 5,
          },
          768: {
            slidesPerView: 6,
          },
          1024: {
            slidesPerView: 8,
          },
        }}
      >
        {recommendeds.map((data, index) => (
          <SwiperSlide>
            <RecommendCard data={data} loading={loading} />
          </SwiperSlide>
        ))}


        {/* Custom Navigation Buttons */}
        <div className={`absolute top-1/2 transform -translate-y-1/2 flex justify-between w-full  z-10 ${loading ? "hidden" : ""}`}>
          <button
            aria-label="previous"
            className="bg-blue-500/50 text-white rounded-full p-1 hover:bg-blue-600 transition duration-200"
            onClick={handlePrev}
          >
            <svg className="w-5 h-5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 19-7-7 7-7" />
            </svg>

          </button>
          <button
            aria-label="next"
            className="bg-blue-500/50 text-white rounded-full p-1 hover:bg-blue-600 transition duration-200"
            onClick={handleNext}
          >
            <svg className="w-5 h-5 text-white font-bold" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m9 5 7 7-7 7" />
            </svg>

          </button>
        </div>
      </Swiper>


    </div>
  );
}

import { apiSlice } from "../services/api";

export const recommendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRecommendeds: builder.mutation({
      query: () => ({
        url: "/recommendeds",
        method: "GET",
      }),
    }),
    createRecommended: builder.mutation({
      query: (formData) => ({
        url: '/recommended',
        method: 'POST',
        body: formData,
       
      }),
    }),
    getRecommendedById: builder.query({
      query: (id) => ({
        url: `/recommended/${id}`,
        method: "GET",
      }),
    }),
    updateRecommended: builder.mutation({
      query: ({ id, formData }) => ({
        url: `/recommended/${id}`,
        method: 'PUT',
        body: formData,
      }),
    }),
    deleteRecommended: builder.mutation({
      query: (id) => ({
        url: `/recommended/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetRecommendedsMutation,
  useCreateRecommendedMutation,
  useGetRecommendedByIdQuery,
  useUpdateRecommendedMutation,
  useDeleteRecommendedMutation,
} = recommendedApiSlice;

import { createSlice } from '@reduxjs/toolkit';

const recommendedSlice = createSlice({
  name: 'recommended',
  initialState: {
    recommendeds: [],
    loading: false,
    error: null
  },
  reducers: {
    setRecommendeds: (state, action) => {
      state.recommendeds = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const { setRecommendeds, setLoading, setError } = recommendedSlice.actions;
export default recommendedSlice.reducer;
export const selectRecommendeds = (state) => state.recommendeds.recommendeds

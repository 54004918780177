import React, { useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css/pagination';

// Import required modules
import { Autoplay } from 'swiper/modules';
import Image from 'next/image';
import Link from 'next/link';

export default function TopRightCarousel({CorauselData, loading, delay, direction}) {

  return (
    <div className=" h-full">
      <Swiper
        direction={direction}
        autoplay={{
          delay: delay,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        modules={[Autoplay]}
        className="mySwiper h-full rounded-lg"
      >
       {CorauselData.map((item, index) => (
          <SwiperSlide key={index}>
             <Link href={`${item.link}`} >
            <Image
              className="w-full h-full object-cover"
              src={item.image}
              width={500} 
              height={500} 
              alt={item.name}
              quality={75}
            />
            </Link>
          </SwiperSlide>
        
      ))}
      </Swiper>
    </div>
  );
}

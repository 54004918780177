import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

function RecommendCard({ data }) {
  return (
    <div className="flex items-center justify-center mx-auto">

      <div className="flex flex-col justify-center items-center w-full mx-auto">
        <Link href={`${data.link}`} >
          <Image
            src={data.image}
            className="h-16 w-16 md:h-32 md:w-36 rounded-3xl object-cover"
            alt={data.name}
            width={125}
            height={125}
            priority={75}
          />
        </Link>
        <span className="flex mt-1 items-center justify-center text-sm text-center">
          {data.label}
        </span>
      </div>

    </div>
  );
}

export default RecommendCard;

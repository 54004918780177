import { useGetCategoriesMutation } from '@/actions/categoryAction';
import { selectCategories, selectLoading, setCategories, setLoading } from '@/reducers/CategoryReducers';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import * as Icons from '@react-icons/all-files';
import * as Icons from 'react-icons/fa';

import { FaBeer } from "@react-icons/all-files"
import Link from 'next/link';

const Sidebar = () => {
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const categories = useSelector(selectCategories);
  const [getCategories] = useGetCategoriesMutation();
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading)

  const getIconComponent = (iconName) => {
    if (Icons[iconName]) {
      return React.createElement(Icons[iconName]);
    }
    return null; // Return null if the icon is not found
  };

  const fetchData = useCallback(async () => {
    dispatch(setLoading(true))
    try {
      const res = await getCategories().unwrap();
      console.log(res)
      if (!res.error) {
        dispatch(setCategories(res));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false))
    }
  }, [getCategories, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="relative flex h-96">
      <div className="w-full bg-white rounded-lg border-r border-gray-300 overflow-y-scroll hide-scrollbar">
        <ul className="space-y-1 text-start px-2 mt-2">
          {
            loading ? (
              Array.from({ length: 10 }).map((_, index) => (
                <li
                  key={index}
                  className="cursor-pointer animate-pulse flex items-center space-x-2 ps-3 hover:bg-gray-200 p-1 rounded-md"
                >
                  <div className="bg-gray-300 w-6 h-6 rounded-full"></div>
                  <div className="bg-gray-300 w-full h-4 rounded"></div>
                </li>
              ))
            ) : (
              categories.map((category) => (
                <li
                  key={category?.id}
                  className="cursor-pointer flex items-center space-x-2 ps-3 hover:bg-gray-200 p-1 rounded-md"
                  onMouseEnter={() => setHoveredCategory(category)}
                >
                  <span className="ps-1">{getIconComponent(category?.icon)}</span>
                  <Link
                    href={{
                      pathname: '/catalog',
                      query: { category: category?.name },
                    }}
                  >{category?.name}</Link>
                </li>
              ))
            )
          }

        </ul>

      </div>

      {/* Subcategory Drawer */}
      {hoveredCategory && hoveredCategory.subcategories.length > 0 && (
        <div
          className="absolute md:left-40 lg:left-48 ms-2 text-start top-0 md:w-[638px] lg:w-[852px] bg-white rounded-lg shadow-lg border border-gray-200 h-full p-4 z-50 overflow-auto custom-scrollbar"
          onMouseEnter={() => setHoveredCategory(hoveredCategory)}
          onMouseLeave={() => setHoveredCategory(null)}
        >
          <h3 className="font-semibold mb-2">{hoveredCategory.category}</h3>
          <div className='border border-dashed'></div>
          <div className="grid grid-cols-3 gap-1 mt-2 ">
            {hoveredCategory?.subcategories?.map((subcategory, index) => (
              <div key={index}>
                <Link
                  href={{
                    pathname: '/catalog',
                    query: { subcategory: subcategory?.name },
                  }}
                  className="font-bold"
                >
                  {subcategory?.name}
                </Link>
                <ul className="mt-2 space-y-1">
                  {subcategory?.childCategories?.map((child, idx) => (
                    <li key={idx} className="text-gray-600">
                      <Link
                        href={{
                          pathname: '/catalog',
                          query: { childCategory: child?.name },
                        }}
                      >
                        {child.name}
                      </Link>
                    </li>
                  ))}
                </ul>

              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
